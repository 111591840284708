import axios from "@/plugins/axios.js"

const actions = {
  fetchGroups({ commit, state}, payload = {}) {
    return new Promise((resolve, reject) => {
      axios.get(`/groups?${new URLSearchParams(payload).toString()}`)
        .then((response) => {
          if(response.data.success){
            commit('SET_GROUPS', response.data.data)
          }
          resolve(response)
        }).catch((error) => { reject(error) })
    })
  }
}

const getters = {
   
}


const mutations = {
  SET_GROUPS(state, data) {
    state.pageData = data
  },
  DELETE_GROUP(state, groupId){
    const itemIndex = state.pageData.data.findIndex((item) => item.id == groupId)
    state.pageData.data.splice(itemIndex, 1)
  },
}

const state = {
  pageData: {
    data: []
  }
}


export default {
  isRegistered: true,
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
}

