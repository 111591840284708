
import { createStore } from 'vuex'

import state from "./state"
import getters from "./getters"
import mutations from "./mutations"
import actions from "./actions"

import moduleLayout from "./modules/layout.js"
import moduleUsers from './modules/users.js'
import moduleNotifications from "./modules/notifications.js"
import moduleGroups from './modules/groups.js'
import moduleMeetings from './modules/meetings.js'

const store = createStore({
    state,
    actions,
    getters,
    mutations,
    modules: {
        layout: moduleLayout,
        userList: moduleUsers,
        notification: moduleNotifications,
        meeting: moduleMeetings,
        group: moduleGroups,
  
    },
    strict: process.env.NODE_ENV !== 'production',
})

export default store
